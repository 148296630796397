import React from "react";
import { IUserOrganization } from "../../interfaces";
import { networkService } from "../../services";
import { useOrganizationStore } from "../../store";
import Header from "../shared/comonents/header.component";
import appConstants from "../shared/config";
import { useOrganization } from "../shared/hooks";
import Applications from "./applications.component";
import Connections from "./connections.component";

const LayoutIntegrations = () => {
  const organization = useOrganization(),
    setOrganization = useOrganizationStore((state) => state.setOrganization);

  React.useEffect(() => {
    const getOrganization = async () => {
      const organization = await networkService.get<IUserOrganization>(
        appConstants.urls.organization
      );

      !!organization && setOrganization(organization);
    };

    getOrganization();
  }, [setOrganization]);

  const renderIntegration = () => {
    if (!organization) {
      return <Connections />;
    }

    return <Applications />;
  };

  return (
    <div className=" bg-[#FFFDF9] min-h-screen font-satoshi">
      <Header />
      {renderIntegration()}
    </div>
  );
};

export default LayoutIntegrations;

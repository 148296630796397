import { signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore, useOrganizationStore } from "../../../store";
import { fbAuth } from "../firebase";

function Header() {
  const navigate = useNavigate(),
    setAuthState = useAuthStore((state) => state.setAuthState),
    setOrganization = useOrganizationStore((state) => state.setOrganization);

  const logOut = async () => {
    await signOut(fbAuth);
    setAuthState();
    setOrganization();
    navigate("/");
  };

  return (
    <header className="container flex items-center justify-between px-4 mx-auto py-7 ">
      <Link to="/integrations" className="flex items-center space-x-4 ">
        <h1 className="text-xl font-medium text-primary">
          Content<span className="text-gray-800 ">0</span>
        </h1>
      </Link>

      <button
        onClick={logOut}
        className="flex items-center space-x-2 text-base text-gray-800 "
      >
        <span>Sign Out</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.4409 15.3699C17.2509 15.3699 17.0609 15.2999 16.9109 15.1499C16.6209 14.8599 16.6209 14.3799 16.9109 14.0899L18.9409 12.0599L16.9109 10.0299C16.6209 9.73994 16.6209 9.25994 16.9109 8.96994C17.2009 8.67994 17.6809 8.67994 17.9709 8.96994L20.5309 11.5299C20.8209 11.8199 20.8209 12.2999 20.5309 12.5899L17.9709 15.1499C17.8209 15.2999 17.6309 15.3699 17.4409 15.3699Z"
            fill="currentColor"
          />
          <path
            d="M19.9298 12.8101H9.75977C9.34977 12.8101 9.00977 12.4701 9.00977 12.0601C9.00977 11.6501 9.34977 11.3101 9.75977 11.3101H19.9298C20.3398 11.3101 20.6798 11.6501 20.6798 12.0601C20.6798 12.4701 20.3398 12.8101 19.9298 12.8101Z"
            fill="currentColor"
          />
          <path
            d="M11.7598 20.75C6.60977 20.75 3.00977 17.15 3.00977 12C3.00977 6.85 6.60977 3.25 11.7598 3.25C12.1698 3.25 12.5098 3.59 12.5098 4C12.5098 4.41 12.1698 4.75 11.7598 4.75C7.48977 4.75 4.50977 7.73 4.50977 12C4.50977 16.27 7.48977 19.25 11.7598 19.25C12.1698 19.25 12.5098 19.59 12.5098 20C12.5098 20.41 12.1698 20.75 11.7598 20.75Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </header>
  );
}

export default Header;

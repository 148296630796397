import { useNavigate } from "react-router-dom";
function Page404() {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen w-screen justify-center items-center">
      <div className="text-center">
        <p className="text-9xl text-primary font-thin">Not Found</p>
        <p className="text-2xl text-gray-500 mt-3">OOPS! NOTHING WAS FOUND</p>
        <p className="text-base text-gray-500 mt-5">
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
        <button
          className="text-center px-5 mt-8 py-3 text-white transition-colors duration-200  rounded-full bg-primary hover:bg-primary/80"
          onClick={() => navigate("/")}
        >
          HOME PAGE
        </button>
      </div>
    </div>
  );
}

export default Page404;

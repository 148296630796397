import React from "react";
import { Link } from "react-router-dom";
import { IBlogPost } from "../../../interfaces";
import { loggerService, networkService } from "../../../services";
import appConstants from "../../shared/config";
import PreviewContext from "../preview.context";

const Blog = () => {
  const [posts, setPosts] = React.useState<Array<IBlogPost>>();
  const { apiKey } = React.useContext(PreviewContext);

  React.useEffect(() => {
    if (!apiKey) {
      return;
    }

    const getBlog = async () => {
      try {
        const result = await networkService.get<Array<IBlogPost>>(
          appConstants.urls.getBlog,
          null,
          {
            headers: {
              "X-C0-TOKEN": apiKey,
            },
          }
        );
        setPosts(result);
      } catch (err) {
        loggerService.error(err);
      }
    };

    getBlog();
  }, [apiKey]);

  if (!apiKey) {
    return;
  }

  return (
    <div className="container flex flex-col items-center px-6 py-8 mx-auto space-y-6">
      <p className="mt-2 text-md text-gray-800">
        C0 API URL:{" "}
        <u>
          <i>{appConstants.urls.getBlog}</i>
        </u>
      </p>

      {(posts || []).map((post) => (
        <div
          key={post.id}
          className="w-full max-w-2xl p-6 bg-gray-100 rounded-2xl"
        >
          <Link to={`${post.id}`}>
            <h3 className="flex items-center mt-2 space-x-3 text-xl font-medium text-gray-800 capitalize hover:underline hover:text-gray-600">
              <span>{post.title}</span>
              <svg
                width="26"
                height="14"
                viewBox="0 0 26 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.6969 1.066C20.3959 0.722005 19.8369 0.679006 19.4929 0.980006C19.1489 1.28101 19.1059 1.84001 19.4069 2.18401L22.9329 6.26901H1.00294C0.529944 6.26901 0.142944 6.65601 0.142944 7.12901C0.142944 7.60201 0.529944 7.98901 1.00294 7.98901H22.7179L19.3639 11.859C19.0629 12.203 19.1059 12.762 19.4499 13.063C19.6219 13.192 19.7939 13.278 20.0089 13.278C20.2669 13.278 20.4819 13.192 20.6539 12.977L25.8139 7.04301L20.6969 1.066Z"
                  fill="black"
                />
              </svg>
            </h3>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Blog;

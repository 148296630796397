import { Switch } from "@headlessui/react";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IOrgApplication, organizationType } from "../../../interfaces";
import FieldError from "../../shared/comonents/field-error.component";
import { AppTypes } from "../../shared/config";
import { useOrganization } from "../../shared/hooks";
import { getFormattedDate } from "../../shared/utils/helper.util";

interface IModalAppDetailsProps {
  app: IOrgApplication;
  onSubmit: (x: Partial<IOrgApplication>) => void;
  syncApplication: (x: string) => void;
  deleteApplication: (x: string) => void;
  setSelectedApp: React.Dispatch<React.SetStateAction<IOrgApplication>>;
}

const appSchema = Yup.object().shape({
  name: Yup.string().required("App name is requied."),
  app_type: Yup.string().required("Please select app type."),
  entity_id: Yup.string().required("Please select notion page."),
});

const ModalAppDetails: React.FC<IModalAppDetailsProps> = ({
  app,
  onSubmit,
  syncApplication,
  deleteApplication,
  setSelectedApp,
}) => {
  const doesAppExist = !!app,
    organization = useOrganization();
  const [organizationSharedItems, setOrganizationSharedItems] = useState<
    organizationType | {}
  >({});
  useEffect(() => {
    const updatedOrganization: organizationType = { pages: [], databases: [] };
    const orgArray = organization.shared_items || [];
    for (let i = 0; i < orgArray.length; i++) {
      if (orgArray[i].type === "page") {
        updatedOrganization.pages.push(orgArray[i]);
      }
      if (orgArray[i].type === "database") {
        updatedOrganization.databases.push(orgArray[i]);
      }
    }
    setOrganizationSharedItems(updatedOrganization);
  }, []);

  const handleCancel = () => {
    setSelectedApp(undefined);
  };

  const copyAPIKey = () => {
    if (!app?.api_key) return;
    navigator.clipboard.writeText(app.api_key);
    toast.success("API key copied successfully.");
  };

  const handleSubmit = (values: Partial<IOrgApplication>) => {
    onSubmit({
      ...app,
      ...values,
    });
  };

  if (!organization?.id) return;
  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
      <div
        onClick={handleCancel}
        className={
          "fixed inset-0 z-40 transition-opacity bg-black/20 backdrop-blur-sm " +
          (doesAppExist ? "block" : "hidden")
        }
      ></div>

      <div
        className={
          "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[32rem] " +
          (doesAppExist ? "translate-x-0 ease-in" : "translate-x-full ease-out")
        }
      >
        <div className="flex items-center justify-between px-6 mt-12 space-x-4 sm:space-x-0">
          <div>
            <h3 className="text-2xl font-bold text-gray-800">
              {!app?.id ? "Create" : "Update"} Application
            </h3>

            <p className="mt-2 text-sm text-gray-600">
              Connected with Notion Page
            </p>
          </div>

          <button
            onClick={handleCancel}
            className="p-2 text-gray-600 transition-colors duration-300 border rounded-full hover:bg-gray-100 focus:text-primary focus:outline-none"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 4.5L4.5 13.5"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.5 4.5L13.5 13.5"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        {doesAppExist && (
          <React.Fragment>
            <main className="flex flex-col flex-1 px-6 py-4 mt-8">
              <Formik
                initialValues={{
                  name: app.name,
                  app_type: app.app_type,
                  entity_id: app.entity_id,
                  auto_sync: app.auto_sync,
                  notify_errors: app.notify_errors,
                }}
                enableReinitialize
                validationSchema={appSchema}
                onSubmit={handleSubmit}
              >
                {({ dirty, values }) => (
                  <Form className="flex flex-col flex-1">
                    <div className="space-y-8 ">
                      <div>
                        <label className="text-sm text-gray-800">
                          Application Name
                        </label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          placeholder="e.g. My Design Blog"
                          className="block w-full px-5 py-3 mt-2 placeholder-gray-400 bg-transparent border-gray-200 rounded-lg focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40"
                        />
                        <ErrorMessage name="name">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>

                      <div>
                        <label className="text-sm text-gray-800">Type</label>
                        <Field
                          as="select"
                          id="app_type"
                          name="app_type"
                          className="block w-full px-5 py-3 mt-2 bg-transparent border-gray-200 rounded-lg focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40"
                        >
                          <option value="">Select</option>
                          <option value={AppTypes.Blog}>Blog</option>
                          <option value={AppTypes.Wiki}>Wiki</option>
                        </Field>
                        <ErrorMessage name="app_type">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>

                      <div>
                        <label className="text-sm text-gray-800">
                          Selected Page
                        </label>
                        <Field
                          as="select"
                          id="entity_id"
                          name="entity_id"
                          className="block w-full px-5 py-3 mt-2 bg-transparent border-gray-200 rounded-lg focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40"
                        >
                          <option value="">Select</option>
                          {(
                            organizationSharedItems[
                              values.app_type == AppTypes.Blog
                                ? "databases"
                                : "pages"
                            ] || []
                          ).map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="entity_id">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>

                      <div>
                        <Field name="auto_sync">
                          {({
                            field: { name, value },
                            form: { setFieldValue, setFieldTouched },
                          }: FieldProps<boolean>) => (
                            <Switch.Group>
                              <Switch
                                checked={value}
                                onChange={(checked) => {
                                  setFieldTouched(name);
                                  setFieldValue(name, checked);
                                }}
                                className={`${
                                  value ? "bg-primary" : "bg-gray-200"
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                              >
                                <span
                                  className={`${
                                    value ? "translate-x-6" : "translate-x-1"
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                              </Switch>
                              <Switch.Label className="ml-3">
                                Enable Auto Sync
                              </Switch.Label>
                            </Switch.Group>
                          )}
                        </Field>
                        {app.auto_sync_time && (
                          <p className="mt-2 text-sm text-gray-500">
                            The app will be re-synced every {app.auto_sync_time}{" "}
                            minutes.
                          </p>
                        )}
                      </div>

                      <div>
                        <Field name="notify_errors">
                          {({
                            field: { name, value },
                            form: { setFieldValue, setFieldTouched },
                          }: FieldProps<boolean>) => (
                            <Switch.Group>
                              <Switch
                                checked={value}
                                onChange={(checked) => {
                                  setFieldTouched(name);
                                  setFieldValue(name, checked);
                                }}
                                className={`${
                                  value ? "bg-primary" : "bg-gray-200"
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                              >
                                <span
                                  className={`${
                                    value ? "translate-x-6" : "translate-x-1"
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                              </Switch>
                              <Switch.Label className="ml-3">
                                App Sync Failed Notifications (Email)
                              </Switch.Label>
                            </Switch.Group>
                          )}
                        </Field>
                      </div>

                      <div className="flex items-end w-full mt-8">
                        <button
                          type="submit"
                          disabled={!dirty}
                          className="w-full px-4 py-3 text-sm font-medium text-center text-white transition-colors duration-200 rounded-full sm:px-6 bg-primary hover:bg-primary/80 disabled:bg-opacity-60"
                        >
                          {!app.id ? "Create" : "Update"} Application
                        </button>
                      </div>

                      {!!app.id && (
                        <React.Fragment>
                          <div>
                            <label className="text-sm text-gray-800">
                              API Key
                            </label>
                            <div className="relative mt-2">
                              <p className="block w-full py-3 pl-5 placeholder-gray-400 bg-transparent border border-gray-200 rounded-lg pr-14 focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40">
                                {app.api_key}
                              </p>
                              <p className="mt-2 text-sm text-gray-500">
                                Add this key in the request as header named{" "}
                                <u>X-C0-TOKEN</u>.
                              </p>
                              <button
                                type="button"
                                onClick={copyAPIKey}
                                className="absolute text-gray-600 hover:text-primary duration-300 transition-colors top-3.5 right-5 "
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.1 22.75H6.9C2.99 22.75 1.25 21.01 1.25 17.1V12.9C1.25 8.99 2.99 7.25 6.9 7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V17.1C16.75 21.01 15.01 22.75 11.1 22.75ZM6.9 8.75C3.8 8.75 2.75 9.8 2.75 12.9V17.1C2.75 20.2 3.8 21.25 6.9 21.25H11.1C14.2 21.25 15.25 20.2 15.25 17.1V12.9C15.25 9.8 14.2 8.75 11.1 8.75H6.9Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M17.1 16.75H16C15.59 16.75 15.25 16.41 15.25 16V12.9C15.25 9.8 14.2 8.75 11.1 8.75H8C7.59 8.75 7.25 8.41 7.25 8V6.9C7.25 2.99 8.99 1.25 12.9 1.25H17.1C21.01 1.25 22.75 2.99 22.75 6.9V11.1C22.75 15.01 21.01 16.75 17.1 16.75ZM16.75 15.25H17.1C20.2 15.25 21.25 14.2 21.25 11.1V6.9C21.25 3.8 20.2 2.75 17.1 2.75H12.9C9.8 2.75 8.75 3.8 8.75 6.9V7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V15.25Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="w-full mt-8 ">
                            <button
                              type="button"
                              onClick={() => syncApplication(app.id)}
                              className="w-full px-4 py-3 text-sm font-medium text-center transition-colors duration-200 rounded-full border border-primary sm:px-6"
                            >
                              Resync Data
                            </button>

                            <p className="mt-4 text-sm text-center text-gray-600">
                              Last synced on -{" "}
                              {getFormattedDate(
                                app?.sync?.timestamp ?? app.updated_at
                              )}
                            </p>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </main>

            {!!app.id && (
              <footer className="px-6 py-8 mt-8 bg-[#FF3535]/10">
                <h3 className="text-[#FF3535]">Danger Zone</h3>

                <p className="mt-3 text-lg text-gray-700">
                  This action cannot be reversed. Once application is deleted it
                  no one would be able to view or access it
                </p>

                <div className="w-full mt-4 ">
                  <button
                    type="button"
                    onClick={() => deleteApplication(app.id)}
                    className="w-full px-4 py-3 font-medium text-center text-[#FF3535] transition-colors duration-200 rounded-full sm:px-6 bg-[#FF3535]/20 hover:bg-[#FF3535]/30"
                  >
                    Delete Application
                  </button>
                </div>
              </footer>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ModalAppDetails;

import React from "react";
import { useParams } from "react-router-dom";
import { IPostDetail } from "../../../interfaces";
import { loggerService, networkService } from "../../../services";
import appConstants from "../../shared/config";
import PreviewContext from "../preview.context";

const Blog = () => {
  const { postId } = useParams();
  const [post, setPost] = React.useState<IPostDetail>();
  const { apiKey } = React.useContext(PreviewContext);

  React.useEffect(() => {
    if (!apiKey || !postId) {
      return;
    }

    const getBlogPost = async () => {
      try {
        const result = await networkService.get<IPostDetail>(
          `${appConstants.urls.getBlog}/${postId}`,
          null,
          {
            headers: {
              "X-C0-TOKEN": apiKey,
            },
          }
        );
        setPost(result);
      } catch (err) {
        loggerService.error(err);
      }
    };

    getBlogPost();
  }, [apiKey, postId]);

  if (!apiKey || !postId) {
    return;
  }

  const renderBanner = (post: IPostDetail) => {
    let banner: string = null;
    if(post?.cover) {
      banner = post.cover;
    } else if(post?.properties?.Img && Array.isArray(post.properties.Img) && !!post.properties.Img.length) {
      banner = post.properties.Img[0];
    }

    return banner ? (
      <img src={banner} alt={post.title} />
    ): <></>;
  }

  return (
    <div className="container flex flex-col items-center px-6 py-8 mx-auto space-y-6">
      <p className="mt-2 text-md text-gray-800">
        C0 API URL:{" "}
        <u>
          <i>{`${appConstants.urls.getBlog}/${postId}`}</i>
        </u>
      </p>

      <main className="max-w-4xl w-full p-6 mx-auto bg-white border rounded-xl">
        {/* post description */}
        {!post ? (
          <div className="w-full p-4 mx-auto border border-gray-300 rounded-md shadow">
            <div className="flex space-x-4 animate-pulse">
              <div className="flex-1 py-1 space-y-6">
                <div className="h-2 rounded bg-slate-700"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 col-span-2 rounded bg-slate-700"></div>
                    <div className="h-2 col-span-1 rounded bg-slate-700"></div>
                  </div>
                  <div className="h-2 rounded bg-slate-700"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            {renderBanner(post)}
            <div
              className="prose prose-slate"
              dangerouslySetInnerHTML={{ __html: post.description }}
            ></div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Blog;

import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { IOrgApplication, organizationType } from "../../../interfaces";
import { ApplicationModel } from "../../../models/organization.model";
import FieldError from "../../shared/comonents/field-error.component";
import { AppTypes, EntityType } from "../../shared/config";
import { useOrganization } from "../../shared/hooks";

interface IAppFirstProps {
  onSubmit: (x: Partial<IOrgApplication>) => void;
}

const appDetails = new ApplicationModel();

const appSchema = Yup.object().shape({
  name: Yup.string().required("App name is requied."),
  app_type: Yup.string().required("Please select app type."),
  entity_id: Yup.string().required("Please select notion page."),
});

const AppFirst: React.FC<IAppFirstProps> = ({ onSubmit }) => {
  const organization = useOrganization();
  const [organizationSharedItems, setOrganizationSharedItems] = useState<
    organizationType | {}
  >({});

  useEffect(() => {
    const updatedOrganization: organizationType = { pages: [], databases: [] };
    const orgArray = organization.shared_items || [];
    for (let i = 0; i < orgArray.length; i++) {
      if (orgArray[i].type === EntityType.page) {
        updatedOrganization.pages.push(orgArray[i]);
      }
      if (orgArray[i].type === EntityType.database) {
        updatedOrganization.databases.push(orgArray[i]);
      }
    }
    setOrganizationSharedItems(updatedOrganization);
  }, []);

  const handleSubmit = (values: Partial<IOrgApplication>) => {
    onSubmit({
      ...appDetails,
      ...values,
    });
  };

  if (!organization?.id) return;

  return (
    <main className="container flex-1 px-4 py-16 mx-auto">
      <h2 className="text-4xl font-bold text-gray-800">
        ⭐ Let's make your first appDetails
      </h2>
      <Formik
        initialValues={{
          name: appDetails.name,
          app_type: appDetails.app_type,
          entity_id: appDetails.entity_id,
        }}
        enableReinitialize
        validationSchema={appSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, values, setFieldValue }) => (
          <Form className="grid grid-cols-1 gap-8 mt-8 md:mt-12 md:grid-cols-2 xl:grid-cols-4">
            <div>
              <label className="text-sm text-gray-800">Application Name</label>

              <Field
                type="text"
                id="name"
                name="name"
                placeholder="e.g. My Design Blog"
                className="block w-full px-5 py-3 mt-2 placeholder-gray-400 bg-transparent border-gray-200 rounded-lg focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40"
              />
              <ErrorMessage name="name">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div>
              <label className="text-sm text-gray-800">Type</label>
              <Field
                as="select"
                id="app_type"
                name="app_type"
                className="block w-full px-5 py-3 mt-2 bg-transparent border-gray-200 rounded-lg focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40"
              >
                <option value={AppTypes.Blog}>Blog</option>
                <option value={AppTypes.Wiki}>Wiki</option>
              </Field>
              <ErrorMessage name="app_type">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div>
              <label className="text-sm text-gray-800">Selected Page</label>
              <Field
                as="select"
                id="entity_id"
                name="entity_id"
                className="block w-full px-5 py-3 mt-2 bg-transparent border-gray-200 rounded-lg focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40"
              >
                <option value="">Select</option>
                {(
                  organizationSharedItems[
                    values.app_type == AppTypes.Blog ? "databases" : "pages"
                  ] || []
                ).map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="entity_id">
                {(msg) => <FieldError message={msg} />}
              </ErrorMessage>
            </div>

            <div className="flex items-end w-full ">
              <button
                type="submit"
                disabled={!dirty}
                className="w-full px-4 py-3 text-sm font-medium text-center text-white transition-colors duration-200 rounded-full sm:px-6 bg-primary hover:bg-primary/80 disabled:bg-opacity-60"
              >
                Create New Application
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </main>
  );
};

export default AppFirst;

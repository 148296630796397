import produce from "immer";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../app/shared/config";
import { IUserOrganization } from "../interfaces";

interface OrganizationState {
  organization: IUserOrganization;
  setOrganization: (x?: IUserOrganization) => void;
}

const useOrganizationStore = create<OrganizationState>()(
  persist(
    devtools(
      (set) => ({
        organization: undefined,
        setOrganization: (data) =>
          set(
            produce((state) => {
              state.organization = data;
            }),
            false,
            "organization/setOrganization"
          ),
      }),
      { name: "organization", serialize: { options: true } }
    ),
    {
      name: appConstants.keys.organization, // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useOrganizationStore;

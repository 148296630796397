import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import image3 from "../../../assets/images/diamond.png";
import { IOrgApplication } from "../../../interfaces";
import { AppTypes } from "../../shared/config";
import { getFormattedDate } from "../../shared/utils/helper.util";

interface IAppCardProps {
  app: IOrgApplication;
  editApplication: React.Dispatch<React.SetStateAction<IOrgApplication>>;
}

const AppCard: React.FC<IAppCardProps> = ({ app, editApplication }) => {
  const copyAPIKey = () => {
    if (!app?.api_key) return;
    navigator.clipboard.writeText(app.api_key);
    toast.success("API key copied successfully.");
  };

  return (
    <div className="flex flex-col px-6 py-6 space-y-4 bg-white border rounded-xl">
      <div className="flex items-center space-x-4">
        <img src={image3} alt="application icon" />
        <div>
          <button
            className="text-lg font-black text-gray-800"
            onClick={() => editApplication(app)}
          >
            {app.name}
          </button>
          <p className="mt-1 text-sm text-gray-600">
            Last synced on -{" "}
            {getFormattedDate(app?.sync?.timestamp ?? app.updated_at)}
          </p>
          <Link
            to={
              app.app_type === AppTypes.Blog ? "/preview/blog" : "/preview/wiki"
            }
            className="mt-1 text-sm text-primary"
          >
            Preview
            <small>&nbsp;(Make sure to copy app's API key)</small>
          </Link>
        </div>
      </div>
      <div className="px-4 py-2 bg-gray-100 border rounded-md">
        <div
          className="flex items-center space-x-2 text-gray-700 cursor-pointer hover:text-primary"
          onClick={copyAPIKey}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6667 5.25H6.41667C5.77233 5.25 5.25 5.77233 5.25 6.41667V11.6667C5.25 12.311 5.77233 12.8333 6.41667 12.8333H11.6667C12.311 12.8333 12.8333 12.311 12.8333 11.6667V6.41667C12.8333 5.77233 12.311 5.25 11.6667 5.25Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.91699 8.74984H2.33366C2.02424 8.74984 1.72749 8.62692 1.5087 8.40813C1.28991 8.18934 1.16699 7.89259 1.16699 7.58317V2.33317C1.16699 2.02375 1.28991 1.72701 1.5087 1.50821C1.72749 1.28942 2.02424 1.1665 2.33366 1.1665H7.58366C7.89308 1.1665 8.18982 1.28942 8.40862 1.50821C8.62741 1.72701 8.75033 2.02375 8.75033 2.33317V2.9165"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="text-sm truncate">{app.api_key}</span>
        </div>
      </div>
    </div>
  );
};

export default AppCard;

import React from "react";
import { toast } from "react-toastify";
import image2 from "../../assets/images/image 6.png";
import { IModalConfirm, IOrgApplication } from "../../interfaces";
import { ModalConfirmModel } from "../../models";
import { loggerService, networkService } from "../../services";
import ModalConfirm from "../shared/comonents/modal-confirm.component";
import appConstants from "../shared/config";
import { useUser } from "../shared/hooks";
import useOrganization from "../shared/hooks/use-organization.hook";
import { getFormattedDate } from "../shared/utils/helper.util";
import AppFirst from "./components/app-first.component";
import AppList from "./components/app-list.component";
import ModalAppDetails from "./components/modal-app-details.component";

function Applications() {
  const user = useUser(),
    organization = useOrganization();

  const [applications, setApplications] =
      React.useState<Array<IOrgApplication>>(),
    [selectedApp, setSelectedApp] = React.useState<IOrgApplication>(),
    [modalConfirm, setModalConfirm] = React.useState<IModalConfirm>(
      () => new ModalConfirmModel()
    );

  const connectNotion = () => {
    window.location.href = `${process.env.REACT_APP_NOTION_AUTH_URL}&state=${user.id}`;
  };

  const getApplications = async () => {
    try {
      const applications = await networkService.get<Array<IOrgApplication>>(
        appConstants.urls.applications
      );
      setApplications(applications);
    } catch (err) {
      loggerService.error("Error while fetching the application details.", err);
    }
  };

  const saveApplication = React.useCallback(async (app: IOrgApplication) => {
    try {
      const payload: Partial<IOrgApplication> = {
        id: app.id,
        name: app.name,
        app_type: app.app_type,
        entity_id: app.entity_id,
        auto_sync: app.auto_sync,
        notify_errors: app.notify_errors,
      };

      if (payload.id) {
        await networkService.put<boolean>(
          appConstants.urls.application,
          payload
        );
        toast.success("Application details updated successfully.");
      } else {
        await networkService.post<boolean>(
          appConstants.urls.application,
          payload
        );
        toast.success("Application details saved successfully.");
      }

      setSelectedApp(null);
      getApplications();
    } catch (err) {
      loggerService.error("Error while saving the application details.", err);
    }
  }, []);

  const deleteApplication = React.useCallback(
    async (appId: string) => {
      setModalConfirm({
        open: true,
        title: "Delete Event",
        message: "Are you sure you want to delete the selected event?",
        onConfirm: async () => {
          try {
            // close modal
            setModalConfirm((prevState) => ({ ...prevState, open: false }));

            // update the API state
            await networkService.delete(
              `${appConstants.urls.application}/${appId}`
            );

            // update the react state
            setSelectedApp(undefined);
            setApplications(applications.filter((item) => item.id !== appId));
            toast.success("Application deleted successfully.");
          } catch (error) {
            loggerService.error(error.mesaage);
          }
        },
      });
    },
    [applications]
  );

  const syncApplication = React.useCallback(async (appId: string) => {
    try {
      // update the API state
      await networkService.get(`${appConstants.urls.syncApplication}/${appId}`);

      // update the react state
      setSelectedApp(undefined);
      toast.success("Sync initiated for the applications.");
    } catch (error) {
      loggerService.error(error.mesaage);
    }
  }, []);

  const renderApplications = () => {
    if (!applications?.length) {
      return <AppFirst onSubmit={saveApplication} />;
    }

    return (
      <AppList applications={applications} editApplication={setSelectedApp} />
    );
  };

  // query applications
  React.useEffect(() => {
    getApplications();
  }, []);

  return (
    <React.Fragment>
      <ModalConfirm {...modalConfirm} setProps={setModalConfirm} />
      <ModalAppDetails
        app={selectedApp}
        onSubmit={saveApplication}
        setSelectedApp={setSelectedApp}
        syncApplication={syncApplication}
        deleteApplication={deleteApplication}
      />

      {applications && (
        <React.Fragment>
          <hr />
          {renderApplications()}
        </React.Fragment>
      )}

      <footer className="container px-4 py-12 mx-auto">
        <hr />
        <div className="grid grid-cols-1 gap-8 mt-12 md:grid-cols-2 xl:grid-cols-3 ">
          <div>
            <h2 className="text-4xl font-semibold text-gray-800">
              Connections
            </h2>
            <p className="mt-4 text-gray-600">
              For additional integrations like Airtable, Microsoft Loop, Google
              docs please reach out to us on{" "}
              <span className=" text-primary">support@content0.io</span>
            </p>
          </div>

          <div className="col-span-2 px-6 py-6 bg-white border lg:px-8 lg:flex lg:items-center lg:justify-between rounded-xl">
            <div className="space-y-6 lg:flex lg:items-center lg:space-y-0 lg:space-x-12">
              <div>
                <img src={image2} alt="" />
              </div>

              <div>
                <p className="text-sm text-gray-600 ">App</p>
                <h3 className="text-lg font-bold text-gray-800">Notion</h3>
              </div>

              <div>
                <p className="text-sm text-gray-600 ">Last Connected</p>
                <h3 className="text-lg font-bold text-gray-800">
                  {getFormattedDate(organization.updated_at)}
                </h3>
              </div>

              <div>
                <p className="text-sm text-gray-600 ">Connected Mail</p>
                <h3 className="text-lg font-bold text-gray-800 truncate">
                  {user.email}
                </h3>
              </div>
            </div>

            <div className="mt-6 lg:mt-0">
              <button
                type="button"
                onClick={connectNotion}
                className="flex items-center px-3 py-3 text-white transition-colors duration-200 rounded-full bg-primary hover:bg-primary/80"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.3333 6.04163H13.3333C12.9916 6.04163 12.7083 5.75829 12.7083 5.41663C12.7083 5.07496 12.9916 4.79163 13.3333 4.79163H18.3333C18.6749 4.79163 18.9583 5.07496 18.9583 5.41663C18.9583 5.75829 18.6749 6.04163 18.3333 6.04163Z"
                    fill="white"
                  />
                  <path
                    d="M5.00008 6.04163H1.66675C1.32508 6.04163 1.04175 5.75829 1.04175 5.41663C1.04175 5.07496 1.32508 4.79163 1.66675 4.79163H5.00008C5.34175 4.79163 5.62508 5.07496 5.62508 5.41663C5.62508 5.75829 5.34175 6.04163 5.00008 6.04163Z"
                    fill="white"
                  />
                  <path
                    d="M8.33341 8.95833C6.38341 8.95833 4.79175 7.36667 4.79175 5.41667C4.79175 3.46667 6.38341 1.875 8.33341 1.875C10.2834 1.875 11.8751 3.46667 11.8751 5.41667C11.8751 7.36667 10.2834 8.95833 8.33341 8.95833ZM8.33341 3.125C7.06675 3.125 6.04175 4.15 6.04175 5.41667C6.04175 6.68333 7.06675 7.70833 8.33341 7.70833C9.60008 7.70833 10.6251 6.68333 10.6251 5.41667C10.6251 4.15 9.60008 3.125 8.33341 3.125Z"
                    fill="white"
                  />
                  <path
                    d="M18.3333 15.2084H15C14.6583 15.2084 14.375 14.925 14.375 14.5834C14.375 14.2417 14.6583 13.9584 15 13.9584H18.3333C18.675 13.9584 18.9583 14.2417 18.9583 14.5834C18.9583 14.925 18.675 15.2084 18.3333 15.2084Z"
                    fill="white"
                  />
                  <path
                    d="M6.66675 15.2084H1.66675C1.32508 15.2084 1.04175 14.925 1.04175 14.5834C1.04175 14.2417 1.32508 13.9584 1.66675 13.9584H6.66675C7.00841 13.9584 7.29175 14.2417 7.29175 14.5834C7.29175 14.925 7.00841 15.2084 6.66675 15.2084Z"
                    fill="white"
                  />
                  <path
                    d="M11.6667 18.125C9.71667 18.125 8.125 16.5333 8.125 14.5833C8.125 12.6333 9.71667 11.0416 11.6667 11.0416C13.6167 11.0416 15.2083 12.6333 15.2083 14.5833C15.2083 16.5333 13.6167 18.125 11.6667 18.125ZM11.6667 12.2916C10.4 12.2916 9.375 13.3166 9.375 14.5833C9.375 15.85 10.4 16.875 11.6667 16.875C12.9333 16.875 13.9583 15.85 13.9583 14.5833C13.9583 13.3166 12.9333 12.2916 11.6667 12.2916Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Applications;

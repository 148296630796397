import React from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./app/app.component";
import "./index.css";

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
    <ToastContainer
      theme="colored"
      bodyClassName="m-0 py-0"
      toastClassName="text-sm font-medium text-gray-800"
    />
  </React.StrictMode>
);

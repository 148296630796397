import { AppTypes } from "../app/shared/config";
import { IOrgApplication } from "../interfaces";

export class ApplicationModel implements IOrgApplication {
  id: string = null;
  name: string = "";
  entity_id: string = "";
  app_type: AppTypes = AppTypes.Blog;
  api_key: string = null;
  auto_sync = false;
  notify_errors = true;
}

export enum UserRole {
  Admin = "OWNER",
  Member = "MEMBER",
}

export enum AppTypes {
  Blog = 1,
  Wiki = 3,
}
export enum EntityType {
  page = "page",
  database = "database",
}

export enum AuthorizationResult {
  Success = 1,
  LoginRequired = 2,
  Unauthorized = 3,
}

export enum RoutePermissionType {
  OneRoleRequired = 1,
  AllRolesRequired = 2,
  OnlyLoginRequired = 3,
}

const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};

const keys = {
  auth: "auth",
  organization: "organization",
  storage: storageTypes.session,
};

class AppURLs {
  baseUrl = process.env.REACT_APP_C0_API_URL;
  organization = `${this.baseUrl}/api/organization`;
  application = `${this.baseUrl}/api/organization/app`;
  applications = `${this.baseUrl}/api/organization/apps`;
  syncApplication = `${this.baseUrl}/api/organization/sync`;

  getBlog = `${this.baseUrl}/api/blog`;
  getWiki = `${this.baseUrl}/api/wiki`;
}

const appConstants = {
  keys,
  urls: new AppURLs(),
};

export default appConstants;

import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const message = searchParams.get("message");
    if (!message) {
      navigate("/");
    }
  }, []);

  return (
    <div className="flex h-screen w-screen justify-center items-center">
      <div className="text-center">
        <p className="text-9xl text-primary font-thin">Oops!</p>
        <p className="text-2xl text-gray-500 mt-4">Something went wrong</p>

        <button
          className="text-center px-5 mt-8 py-3 text-white transition-colors duration-200  rounded-full bg-primary hover:bg-primary/80"
          onClick={() => navigate("/")}
        >
          HOME PAGE
        </button>
      </div>
    </div>
  );
}

export default ErrorPage;

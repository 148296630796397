import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Outlet } from "react-router-dom";
import * as Yup from "yup";
import FieldError from "../shared/comonents/field-error.component";
import Header from "../shared/comonents/header.component";
import PreviewContext from "./preview.context";

const previewSchema = Yup.object().shape({
  apiKey: Yup.string().required("API key is required."),
});

const LayoutPreview = () => {
  const [apiKey, setApiKey] = React.useState<string>();

  const handleSubmit = (values: { apiKey: string }) => {
    setApiKey(values.apiKey);
  };

  return (
    <PreviewContext.Provider value={{ apiKey }}>
      <div className=" bg-[#FFFDF9] min-h-screen font-satoshi">
        <Header />

        <main className="container px-4 mx-auto">
          <Formik
            initialValues={{
              apiKey: "",
            }}
            validationSchema={previewSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty }) => (
              <Form className="flex flex-col flex-1">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 px-8 py-6 bg-white border rounded-xl">
                  <div className="md:col-span-2">
                    <label className="text-sm text-gray-800">C0 API Key</label>
                    <div className="relative mt-2">
                      <Field
                        type="text"
                        id="apiKey"
                        name="apiKey"
                        placeholder="Enter API key of App"
                        className="block w-full px-5 py-3 mt-2 placeholder-gray-400 bg-transparent border-gray-200 rounded-lg focus:ring focus:ring-opacity-40 focus:ring-primary/10 focus:border-primary/40"
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        Add this key in the REST API request as header named{" "}
                        <u>X-C0-TOKEN</u>.
                      </p>
                      <ErrorMessage name="apiKey">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="flex items-start w-full mt-8">
                    <button
                      type="submit"
                      disabled={!dirty}
                      className="w-full px-4 py-3 text-sm font-medium text-center text-white transition-colors duration-200 rounded-full sm:px-6 bg-primary hover:bg-primary/80 disabled:bg-opacity-60"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <Outlet />
        </main>
      </div>
    </PreviewContext.Provider>
  );
};

export default LayoutPreview;

import { IOrgApplication } from "../../../interfaces";
import { ApplicationModel } from "../../../models/organization.model";
import AppCard from "./app-card.component";

interface IAppListProps {
  applications: Array<IOrgApplication>;
  editApplication: React.Dispatch<React.SetStateAction<IOrgApplication>>;
}

const AppList: React.FC<IAppListProps> = ({ applications, editApplication }) => {
  console.log("ahkjahsdkhsa");
  return (
    <main className="container flex-1 px-4 py-16 mx-auto">
      <div className="md:flex md:items-center md:justify-between ">
        <h2 className="text-3xl font-bold text-gray-800 sm:text-4xl">
          {applications.length} Applications Created
        </h2>

        <button
          type="button"
          onClick={() => editApplication(new ApplicationModel())}
          className="w-full px-4 py-3 mt-6 text-sm font-medium text-center text-white transition-colors duration-200 rounded-full md:mt-0 md:w-auto md:px-6 bg-primary hover:bg-primary/80"
        >
          Create New Application
        </button>
      </div>

      <div className="grid grid-cols-1 gap-8 mt-8 md:mt-12 md:grid-cols-2 xl:grid-cols-3">
        {applications.map((item) => (
          <AppCard key={item.id} app={item} editApplication={editApplication} />
        ))}
      </div>
    </main>
  );
};

export default AppList;

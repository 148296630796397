import produce from "immer";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../app/shared/config";
import { IAuthState, IUserInfo } from "../interfaces";

interface AuthState {
  token: string;
  user: IUserInfo;
  loading: boolean;
  setAuthState: (x?: IAuthState) => void;
  updateAuthUser: (x: Partial<IUserInfo>) => void;
  setLoading: (x: boolean) => void;
}

const useAuthStore = create<AuthState>()(
  persist(
    devtools(
      (set) => ({
        loading: false,
        token: undefined,
        user: undefined,
        setAuthState: (data) =>
          set(
            produce((state) => {
              state.token = data?.token || undefined;
              state.user = data?.user || undefined;
            }),
            false,
            "auth/setAuthState"
          ),
        updateAuthUser: (changes) =>
          set(
            produce((state) => {
              state.user = { ...state.user, ...changes };
            }),
            false,
            "auth/updateAuthUser"
          ),
        setLoading: (data) => set((state) => ({ loading: data })),
      }),
      { name: "auth", serialize: { options: true } }
    ),
    {
      name: appConstants.keys.auth, // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAuthStore;

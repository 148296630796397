import { AxiosError } from "axios";
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";
import appConstants from "../../app/shared/config";
import { fbAuth } from "../../app/shared/firebase";
import { helper } from "../../app/shared/utils";
import { IServerError } from "../../interfaces";

const responseInterceptor = async (err: AxiosError<IServerError>) => {
  const parsedError = {
    status: helper.getPropertyValue<number>(err, "response.status", 400),
    message: helper.getPropertyValue<string>(
      err,
      "response.data",
      "An error occured while processing your request. Please try again or contact system administrator."
    ),
  };

  if (parsedError.status.toString() === "403") {
    // ToDo: logout the user
    await signOut(fbAuth);
    sessionStorage.removeItem(appConstants.keys.auth);
    sessionStorage.removeItem(appConstants.keys.organization);
    window.location.href = "/";
  }

  // throw an error to services/ components
  toast.error(parsedError.message);
  return Promise.reject<IServerError>(parsedError);
};

export default responseInterceptor;

import image1 from "../../assets/images/image 6-1.png";
import image2 from "../../assets/images/image 6.png";
import { useUser } from "../shared/hooks";

function Connections() {
  const user = useUser();

  const connectNotion = () => {
    window.location.href = `${process.env.REACT_APP_NOTION_AUTH_URL}&state=${user.id}`;
  };

  return (
    <main className="container px-4 py-24 mx-auto">
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 ">
        <div className="md:col-span-2 xl:col-span-1">
          <h2 className="text-4xl font-semibold text-gray-800">Connections</h2>
          <p className="mt-4 text-gray-600">
            For additional integrations like Airtable, Microsoft Loop, Google
            docs please reach out to us on{" "}
            <span className=" text-primary">support@content0.io</span>
          </p>
        </div>

        <div className="px-8 py-6 bg-white border sm:flex sm:items-center sm:justify-between rounded-xl">
          <div className="flex items-center space-x-4">
            <img src={image2} alt="" />
            <div>
              <h3 className="text-lg font-bold text-gray-800 ">Notion</h3>
              <button
                type="button"
                onClick={connectNotion}
                className="flex items-center space-x-2 font-normal text-gray-600 "
              >
                <span>notion.so</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 2H14V6"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66666 9.33333L14 2"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <button
            type="button"
            onClick={connectNotion}
            className="w-full px-4 py-3 mt-6 text-sm font-medium text-center text-white transition-colors duration-200 rounded-full sm:mt-0 sm:w-auto sm:px-6 bg-primary hover:bg-primary/80"
          >
            Connect Now
          </button>
        </div>

        <div className="px-8 py-6 bg-white border sm:flex sm:items-center sm:justify-between rounded-xl">
          <div className="flex items-center space-x-4">
            <img src={image1} alt="" />
            <div>
              <h3 className="text-lg font-bold text-gray-400 ">Notion</h3>
              <button
                type="button"
                className="flex items-center space-x-2 font-normal text-gray-400 "
              >
                <span>Airtable</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 2H14V6"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66666 9.33333L14 2"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <button className="w-full px-4 py-3 mt-6 text-sm font-medium text-center transition-colors duration-200 rounded-full sm:mt-0 sm:w-auto sm:px-6 text-primary bg-primary/5 hover:bg-primary/10">
            Coming Soon
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8 mt-20 lg:mt-40 md:grid-cols-2 xl:grid-cols-3 ">
        <div className="md:col-span-2 xl:col-span-1">
          <h2 className="text-4xl font-semibold text-gray-800">Applications</h2>
          <p className="relative mt-4 text-gray-600">
            <span>
              At least one integration needs to be active to create applications
            </span>
            <svg
              className="absolute -top-1.5 left-14"
              width="158"
              height="32"
              viewBox="0 0 158 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M89.3987 6.58513C74.5653 4.7518 20.0987 1.38515 6.89868 10.5851C-9.60132 22.0851 11.8987 29.5852 29.8987 30.5852C47.8987 31.5852 117.399 31.5852 144.399 26.0852C150.065 24.4185 160.399 19.7851 156.399 14.5851C151.399 8.0851 102.399 0.0851544 77.3987 1.08521"
                stroke="#FF4F00"
              />
            </svg>
          </p>
        </div>

        <div className="px-8 py-6 bg-white border animate-pulse rounded-xl">
          <div className="flex items-center space-x-4">
            <div className="w-12 h-12 bg-gray-200 "></div>

            <div>
              <h3 className="h-3 bg-gray-200 w-28 ">&nbsp;</h3>
              <p className="w-48 h-2 mt-4 bg-gray-200"></p>
            </div>
          </div>
        </div>

        <div className="px-8 py-6 bg-white border animate-pulse rounded-xl">
          <div className="flex items-center space-x-4">
            <div className="w-12 h-12 bg-gray-200 "></div>

            <div>
              <h3 className="h-3 bg-gray-200 w-28 ">&nbsp;</h3>
              <p className="w-48 h-2 mt-4 bg-gray-200"></p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Connections;

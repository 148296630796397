import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { RoutePermission } from "../models";

import Home from "./home/home.component";
import LayoutIntegrations from "./integrations/integrations.component";
import ErrorPage from "./pages/ErrorPage.component";
import Page404 from "./pages/Page404.component";
import Blog from "./preview/blog/blog.component";
import BlogPost from "./preview/blog/post.component";
import LayoutPreview from "./preview/preview.component";
import Wiki from "./preview/wiki/wiki.component";
import PrivateRoute from "./shared/comonents/private-route.component";
import { RoutePermissionType } from "./shared/config";

const loginPermission = RoutePermission.factory(
  [],
  RoutePermissionType.OnlyLoginRequired
);

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route element={<PrivateRoute routePermission={loginPermission} />}>
            <Route path="integrations" element={<LayoutIntegrations />}></Route>
            <Route path="preview" element={<LayoutPreview />}>
              <Route index element={<Navigate to="blog" />}></Route>
              <Route path="blog" element={<Outlet />}>
                <Route index element={<Blog />}></Route>
                <Route path=":postId" element={<BlogPost />}></Route>
              </Route>
              <Route path="wiki" element={<Outlet />}>
                <Route index element={<Wiki />}></Route>
                <Route path=":postId" element={<Wiki />}></Route>
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Page404 />}></Route>
          <Route path="/error" element={<ErrorPage />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
